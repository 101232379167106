/* Base */
@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Components */
@import 'components/button';
@import 'components/form';

/* Utilities */
@import 'tailwindcss/utilities';
